
// This file was generated by running 'ng generate @angular/material:theme-color'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #fbe037, secondary: #330033
$_palettes: (
  primary: (
    0: #000000,
    10: #211c00,
    20: #383000,
    25: #443b00,
    30: #514700,
    35: #5e5200,
    40: #6c5e00,
    50: #887700,
    60: #a49100,
    70: #c2ab00,
    80: #e0c717,
    90: #fee33a,
    95: #fff1b3,
    98: #fff9eb,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #370336,
    20: #4f1b4d,
    25: #5c2659,
    30: #693265,
    35: #773e71,
    40: #844a7e,
    50: #a06298,
    60: #bc7bb3,
    70: #d995cf,
    80: #f7b0ec,
    90: #ffd7f5,
    95: #ffebf8,
    98: #fff7f9,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #131f00,
    20: #243600,
    25: #2d4200,
    30: #364e00,
    35: #3f5b00,
    40: #496800,
    50: #5c8300,
    60: #749e1f,
    70: #8eb93a,
    80: #a8d553,
    90: #c3f26c,
    95: #d6ff8a,
    98: #f1ffd2,
    99: #f9ffe6,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1e1c11,
    20: #333025,
    25: #3e3b2f,
    30: #4a473a,
    35: #565245,
    40: #625e51,
    50: #7b7769,
    60: #959181,
    70: #b0ab9b,
    80: #ccc6b6,
    90: #e9e2d1,
    95: #f7f0df,
    98: #fff9eb,
    99: #fffbff,
    100: #ffffff,
    4: #100e05,
    6: #151309,
    12: #222015,
    17: #2d2a1f,
    22: #383529,
    24: #3c392d,
    87: #e0dac9,
    92: #eee8d6,
    94: #f4eddc,
    96: #faf3e2,
  ),
  neutral-variant: (
    0: #000000,
    10: #1f1c0b,
    20: #34311e,
    25: #403c28,
    30: #4b4733,
    35: #57533e,
    40: #635e49,
    50: #7d7761,
    60: #979179,
    70: #b2ab92,
    80: #cec6ac,
    90: #eae2c7,
    95: #f9f1d5,
    98: #fff9eb,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);

$primary-palette: map.merge(map.get($_palettes, primary), $_rest);
$tertiary-palette: map.merge(map.get($_palettes, tertiary), $_rest);

html {
  color-scheme: light;
  @include mat.theme((
    color: $primary-palette,
    typography: var(--faw-font-family),
    density: 0
  ));
}
