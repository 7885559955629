:root {
  --faw-font-family: 'Lalezar', system-ui, sans-serif;
  --faw-material-font: 'Material Icons Outlined';
  --faw-font-size: 20px;
  --faw-font-weight: 300;
  --faw-font-size-mega: 3rem;
  --faw-font-size-xlarge: 1.8rem;
  --faw-font-size-larger: 1.2rem;
  --faw-font-size-smaller: 0.8rem;

  --faw-text-color: #333;

  --faw-color-gray: #838383;
  --faw-color-gray-light: #c9c9c9;
  --faw-color-gray-lighter: #eaeaea;
  --faw-color-gray-lightest: #f7f7f7;
  --faw-color-gray-med: #a5a5a5;

  --faw-color-red: #950705;
  --faw-color-green: #00ad00;
  --faw-color-orange: #ff8f0c;
  --faw-color-purple: #330033;
  --faw-color-black-trans: rgba(0, 0, 0, 0.3);
  --faw-color-black-trans-dark: rgba(0, 0, 0, 0.8);
  --faw-color-yellow: #fbe037;

  --faw-border-radius: 4px;

  --mat-menu-container-color: white;
  --mat-sys-background: #f7f7f7;
  --mat-sys-surface: white;
  --mat-sys-primary: black;
  --mat-sys-surface-container-low: white;
}

