.lg-sub-html {
  font-family: var(--faw-font-family);

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0.25rem;

  time:before {
    display: inline-block;
    content: '-';
    margin-right: 0.25rem;
  }
}

.lg-toolbar {
  .lg-counter {
    font-family: var(--faw-font-family);
  }

  &:hover {
    transition: background-color 0.3s linear;
    background-color: var(--faw-color-black-trans-dark);

    .lg-icon, .lg-counter {
      color: white;
    }
  }
}

.lg-thumb-item {
  &[data-video] {
    position: relative;

    &::after {
      content: '\e1c4';
      position: absolute;
      inset: 0;
      font-family: var(--faw-material-font);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
      color: white;
    }
  }

  &.active {
    border-color: var(--faw-color-yellow) !important;
  }
}

