@use "mixins/viewport.mixin" as *;
@use "./lightbox.overrides" as *;
@use "./forms" as *;

.mat-mdc-simple-snack-bar {
  font-family: var(--faw-font-family);
  font-size: 1rem;
  justify-content: center !important;
}

.chips-wrapper {
  justify-content: center;

  &:active, &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
}

.mat-mdc-card {
  padding: 1rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip, .mat-mdc-chip {
  justify-content: center !important;

  &.hover {
    cursor: pointer;
  }

  &-disabled {
    opacity: 1 !important;
    cursor: default;
  }

  &.mat-purple {
    background-color: var(--faw-color-purple);
    color: white;
  }
}

.mat-mdc-dialog-actions {
  flex-wrap: nowrap !important;
}

mat-checkbox, .mat-mdc-checkbox {
  label {
    max-width: 100%;
    overflow: hidden;
    color: var(--faw-color-gray);
  }

  .mat-checkbox-label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &.mat-checkbox-checked label {
    color: var(--faw-text-color);
  }
}


.mat-tab-body-content {
  overflow-x: hidden !important;
}

.mat-icon {
  --size: 1em;
  width: var(--size) !important;
  height: var(--size) !important;
  font-size: var(--size) !important;
  line-height: 1 !important;
}

.mdc-button, .mat-mdc-button, .mat-mdc-raised-button {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25em;
  transition: 0.1s linear;
  padding: 0.25rem 0.5rem !important;
  min-width: 5rem !important;
  border-width: 2px !important;

  * {
    font-size: 1em;
    line-height: 1;
  }

  &[color=white] {
    --mat-sys-primary: white;
    --mat-sys-on-primary: black;

    &:hover {
      background-color: black;
      color: white;
    }
  }
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 1rem !important;
}

.mat-mdc-icon-button {
  width: auto !important;
}

.mat-mdc-icon, mat-icon {
  font-family: var(--faw-material-font) !important;
}

.mat-option-text {
  line-height: 1em;

  small.block {
    font-style: italic;
    display: block;
    color: var(--faw-color-gray-light);
  }
}

.mat-expansion-panel-header {
  font-size: inherit;
}

.mat-expansion-panel-header-title {
  align-items: center;
  padding-left: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 !important;
}

div[role=listbox] {
  .mat-mdc-option {
    font-size: var(--faw-font-size-smaller);
  }
}




// -------------------------------     R E S P O N S I V E N E S S     ---------------------------------- //

@include viewport(md) {
  .mat-mdc-dialog-actions {
    flex-wrap: wrap !important;
    justify-content: center;

    button:first-child {
      margin-bottom: 0.5rem;
    }
  }
}
