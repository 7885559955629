@use "sass:map" as map;
@use 'scss/fonts' as *;
@use 'scss/variables'as *;
@use 'scss/theme'as *;
@use 'scss/base'as *;
@use 'scss/galleries'as *;
@use 'scss/placeholders'as *;
@use 'scss/forms'as *;
@use 'scss/icons'as *;
@use "scss/breakpoints" as *;

@use 'scss/overrides/index' as *;
@import url('https://fonts.googleapis.com/css?family=Lalezar&display=swap');


html {
  font-family: var(--faw-font-family);
  font-weight: var(--faw-font-weight);
  font-size: var(--faw-font-size);
  color: var(--faw-text-color);
  scroll-behavior: smooth;
}

body {
  margin: 0;

  &.no-scroll {
    overflow-y: hidden;
  }
}

h1, h2, h3, h4 {
  font-family: var(--faw-font-family);
}

.side-nav form, mat-form-field {
  width: 100%;
}

#lightgallery.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: black;
}

.cdk-overlay-container {
  --mat-menu-item-label-text-color: black;
  --mat-autocomplete-background-color: white;
  --mat-option-label-text-color: black;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media screen and (width > $breakpoint) {
    .container {
      max-width: map.get($container-max-widths, $key);
      margin-inline: auto;
    }
  }
}



