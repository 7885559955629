input[readonly] {
  cursor: not-allowed;
}

.password-toggle {
  color: var(--color, currentColor);
  cursor: pointer;

  &:hover {
    color: var(--faw-color-orange)
  }
}
