a {
  &, &:hover {
    color: var(--faw-text-color);
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
}

.flex-wrapper {
  display: flex;
  align-items: center;
}

.chips-wrapper {
  margin: 0 -0.25rem;
  display: inline-grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  max-width: 100%;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
